import React, {useState} from 'react';
import {Button, Card, Container, Form, Header, Message} from "semantic-ui-react";
import firebase from "firebase/app";
import "firebase/auth";

function HomePage() {

    return (
        <Container>

            Welcome!

        </Container>
    );
}

export default HomePage;
