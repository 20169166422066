import React, {useState, useEffect} from 'react';
import './App.scss';
import {Container, Header, Loader} from "semantic-ui-react";
import firebase from "firebase/app";
import "firebase/auth";
import SignInPage from "./pages/SignIn";
import Navigation from "./components/Navigation";

function App() {

    // Null: loading, false: not signed in
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (user) return;
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                setUser(user);
            } else {
                setUser(false);
            }
        })
    });

    return <>
        <Container className="App">

            {user === null ? <div style={{marginTop: '20%'}}>
                <Loader active inline='centered' />
            </div> : user === false ? <SignInPage/> :
                <Navigation />
            }

        </Container>
    </>;
}

export default App;
