import React, {useState, useEffect} from 'react';
import firebase from "firebase/app";
import "firebase/database";
import update from "immutability-helper";
import {Button, Card, Checkbox, Container, Form, Grid, Label, Menu, TextArea} from "semantic-ui-react";

function FAQPage() {

    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [originalItems, setOriginalItems] = useState([]);
    const [activeItem, setActiveItem] = useState("");

    useEffect(() => {

        firebase.database().ref("faqitems").get().then(function (snapshot) {
            if (snapshot.exists()) {
                let items = snapshot.val();
                for (let key of Object.keys(items)) {
                    items[key].key = key;

                }
                setItems(items);
                setOriginalItems(items);
            }
        });

    }, []);

    const updateItem = (label, value) => {
        setItems(update(items, {
            [activeItem]: {
                [label]: {$set: value}
            }
        }))
    }

    const save = () => {
        console.log("SAVE");
        setLoading(true);
        firebase.database().ref("faqitems").child(activeItem).set(items[activeItem])
            .then(function () {
                setOriginalItems(items);
                setLoading(false);
                setActiveItem("");
            });
    }

    const orderedItems = Object.values(items).sort((a, b) => a.order > b.order);

    const newItem = () => {
        const key = new Date().getTime();
        setItems(update(items, {
            [key]: {
                $set: {
                    key,
                    subject: "",
                    content: "",
                    active: false,
                    order: parseInt(orderedItems[orderedItems.length - 1].order, 10) + 1,
                }
            }
        }))
        setActiveItem(key);
    }

    const move = (e, direction, i) => {
        e.stopPropagation();
        e.preventDefault();

        if (direction === "down") {
            let newOrder = parseInt(orderedItems[i].order, 10) + 1;
            setItems(update(items, {
                [activeItem]: {
                    order: {$set: newOrder}
                }
            }))
        } else if (direction === "up") {
            let newOrder = parseInt(orderedItems[i].order, 10) - 1;
            setItems(update(items, {
                [activeItem]: {
                    order: {$set: newOrder}
                }
            }))
        }

        return false;

    }

    const cancel = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setItems(originalItems);
        setActiveItem("");
        return false;
    }

    return (
        <Container>

            <Form onSubmit={save}>

                {orderedItems.map((item, i) => <Card fluid key={item.key}
                                                onClick={() => !loading && setActiveItem(item.key)}>
                    <Card.Content>
                        <Card.Header>
                            {activeItem === item.key ?
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={12}>
                                            {/*({item.order})*/}
                                            <input type="text" value={item.subject}
                                                   onChange={(e) =>
                                                       updateItem("subject", e.target.value)}/>
                                        </Grid.Column>
                                        <Grid.Column width={4} textAlign="right">
                                            <Checkbox toggle label="Visible" checked={item.active}
                                                      onChange={(e, data) => updateItem("active", data.checked)}
                                                      style={{marginRight: 20}}/>

                                            <Button icon="angle up" circular size="mini" onClick={(e) => move(e, "up", i)} disabled={i === 0}/>
                                            <Button icon="angle down" circular size="mini" onClick={(e) => move(e, "down", i)} disabled={i === orderedItems.length-1}/>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid> : <>
                                    {/*({item.order})*/}
                                    {item.subject}
                                    <Label style={{float: "right"}} color={item.active ? "green" : "orange"}>
                                        {item.active ? "visible" : "hidden"}
                                    </Label>
                                </>}
                        </Card.Header>
                    </Card.Content>
                    {activeItem === item.key &&
                    <Card.Content>
                        <TextArea value={item.content} style={{minHeight: 150}}
                                  onChange={(e) => updateItem("content", e.target.value)}
                                  disabled={loading}/><br/><br/>
                        <Button type='submit' primary floated="right" loading={loading}>Save</Button>
                        <Button floated="right" onClick={cancel} disabled={loading}>Cancel</Button>
                    </Card.Content>}
                </Card>)}
            </Form>

            <br/><br/>

            <Button primary floated="right" onClick={newItem} disabled={loading}>Add item</Button>

        </Container>
    );
}

export default FAQPage;
