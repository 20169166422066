import React, {useState} from 'react';
import {Button, Card, Container, Form, Header, Image, Message} from "semantic-ui-react";
import firebase from "firebase/app";
import "firebase/auth";
import {ConfigContext} from "../../index";

function SignInPage() {

    const [email, updateEmail] = useState('');
    const [password, updatePassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = () => {
        setErrorMessage('');
        setLoading(true);

        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
            .then(() => {
                return firebase.auth().signInWithEmailAndPassword(email, password)
            })
            .then(() => {
                // Signed in, Auth listener in <App> changes site

            })
            .catch((error) => {
                let errorCode = error.code;
                setErrorMessage(error.message);
                console.error(error);
            }).finally(() => {
                setLoading(false);
        })

    }

    return (
        <Container>

            <br/><br/><br/>

            <Card centered raised>
                <Card.Content>
                    <ConfigContext.Consumer>
                        {value => <Image src={"/resources/images/" + value.image} />}
                    </ConfigContext.Consumer>
                </Card.Content>
                <Card.Content>

                    <h1>Sign in</h1>

                    {errorMessage &&
                    <Message negative>
                        <Message.Header>Something went wrong</Message.Header>
                        <p>{errorMessage}</p>
                    </Message>}

                    <Form onSubmit={handleSubmit} loading={loading}>
                        <Form.Field>
                            <label>E-mail address</label>
                            <input placeholder='E-mail address' value={email} onChange={e => updateEmail(e.target.value)} />
                        </Form.Field>
                        <Form.Field>
                            <label>Password</label>
                            <input placeholder='Password' type="password" value={password} onChange={e => updatePassword(e.target.value)}/>
                        </Form.Field>
                        <Button type='submit' primary>Submit</Button>

                    </Form>

                </Card.Content>
            </Card>

        </Container>
    );
}

export default SignInPage;
