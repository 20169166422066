import React from 'react';
import {Card, Container, Grid, Header, Image, Menu} from "semantic-ui-react";
import styles from "./style.scss";
import {BrowserRouter as Router, NavLink, Route, Switch} from "react-router-dom";
import SettingsPage from "../../pages/Settings";
import HomePage from "../../pages/Home";

import firebase from "firebase/app";
import "firebase/auth";
import TextsPage from "../../pages/Texts";
import {ConfigContext} from "../../index";
import TextsPageSingleField from "../../pages/Texts/singleField";
import FAQPage from "../../pages/Texts/faq";

function Navigation() {

    const signOut = () => {
        firebase.auth().signOut().then(() => {
            window.location.reload();
        })
    }

    const getTitle = title => {
        title = title.replace(/-/g, ' ');
        return title[0].toUpperCase() + title.substring(1);
    }

    return <Router>
        <Container style={styles.container}>
            <Header as='h1'>
                <Header.Content>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <ConfigContext.Consumer>
                                    {value => <Image src={"/resources/images/" + value.image}/>}
                                </ConfigContext.Consumer>
                            </Grid.Column>
                            <Grid.Column width={12}>

                                Live Megawatt Webpanel
                                <Header.Subheader>
                                    <ConfigContext.Consumer>
                                        {value => value.name}
                                    </ConfigContext.Consumer>
                                </Header.Subheader>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Header.Content>
            </Header>

            <Menu>

                <ConfigContext.Consumer>
                    {value => value.menu.map(item =>
                        <Menu.Item to={item.link || "/" + item.type} as={NavLink} exact>
                            {item.title || getTitle(item.type)}
                        </Menu.Item>)}
                </ConfigContext.Consumer>

                <Menu.Menu position='right'>
                    <Menu.Item onClick={signOut}>
                        Sign Out
                    </Menu.Item>
                </Menu.Menu>

            </Menu>

            <Switch>
                <Route path={"/"} exact component={HomePage}/>
                <Route path={"/texts/singleField/:SFPath?"} component={TextsPage}/>
                <Route path={"/texts/:path?"} component={TextsPage}/>
                <Route path={"/faq"} component={FAQPage}/>
                <Route path={"/settings"} component={SettingsPage}/>
            </Switch>

        </Container>
    </Router>;
}

export default Navigation;
