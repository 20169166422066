import React, {useState, useEffect} from 'react';
import firebase from "firebase/app";
import "firebase/database";
import {Card, Container, Grid, Menu} from "semantic-ui-react";
import ContentEditor from "../../components/ContentEditor"
import {NavLink} from "react-router-dom";
import {ConfigContext} from "../../index";
import SingleFieldCard from "./singleFieldCard";

function TextsPage({match}) {

    const {params} = match;
    const activeItem = params.path;
    const activeSFPath = params.SFPath;

    const [items, setItems] = useState([]);

    useEffect(() => {

        firebase.database().ref("info").get().then(function (snapshot) {
            if (snapshot.exists()) {
                let items = snapshot.val();
                for (let key of Object.keys(items)) {
                    let item = items[key];
                    if (!item.content && !item[0]){
                        delete items[key];
                    }
                }
                setItems(items);
            }
        });

    }, []);

    const getTitle = title => {
        title = title.replace(/-/g, ' ');
        return title[0].toUpperCase() + title.substring(1);
    }

    return (
        <Container>

            <Grid stackable>

                <Grid.Row>
                    <Grid.Column width={4}>
                        <Menu vertical fluid>
                            {items && Object.keys(items).map((item, i) => <Menu.Item
                                as={NavLink}
                                key={'item' + i}
                                name={item}
                                to={"/texts/" + item}
                                // active={activeItem === item}
                                // onClick={(_, menuItem) => setActiveItem(menuItem.name)}
                            >
                                {getTitle(item)}
                            </Menu.Item>)}

                            <ConfigContext.Consumer>
                                {value => <>
                                    {value.texts.singleField &&
                                    value.texts.singleField.map((item, i) =>
                                        <Menu.Item
                                            as={NavLink}
                                            key={'itemSF' + i}
                                            name={item.title}
                                            to={"/texts/singleField/" + encodeURIComponent(item.path)}
                                        >
                                            {item.title}
                                        </Menu.Item>)}
                                    </>
                                }
                            </ConfigContext.Consumer>

                        </Menu>

                    </Grid.Column>
                    <Grid.Column width={12}>

                        {activeItem && activeItem !== "" && <Card fluid>
                            <Card.Content>
                                <Card.Header>
                                    {getTitle(activeItem)}
                                </Card.Header>
                            </Card.Content>
                            <Card.Content>
                                <ContentEditor path={activeItem} item={items[activeItem]} />
                            </Card.Content>
                        </Card>}

                        {activeSFPath && activeSFPath !== "" && <SingleFieldCard path={activeSFPath} />}
                    </Grid.Column>

                </Grid.Row>
            </Grid>

        </Container>
    );
}

export default TextsPage;
