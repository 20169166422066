import React, {useState, useEffect} from 'react';
import {Button, Card, Form, TextArea} from "semantic-ui-react";
import ContentEditor from "../../components/ContentEditor";
import firebase from "firebase";

const SingleFieldCard = ({path}) => {

    let itemPath = decodeURIComponent(path);
    let [loading, setLoading] = useState(false);
    let [text, setText] = useState();
    let [originalText, setOriginalText] = useState();

    useEffect(() => {

        firebase.database().ref("settings/" + itemPath).get().then(function (snapshot) {
            if (snapshot.exists()) {
                let text = snapshot.val();
                setText(text);
                setOriginalText(text);
            }
        });

    }, [path]);

    const save = () => {
        setLoading(true);
        firebase.database().ref("settings/" + itemPath).set(text).then(function () {
            setLoading(false);
            setOriginalText(text);
        });
    }

    return <Card fluid>
        <Card.Content>
            <Card.Header>
                {itemPath && itemPath.split("/").pop()}
            </Card.Header>
        </Card.Content>
        <Card.Content>
            <Form onSubmit={save} loading={loading}>
                <TextArea value={text} onChange={(e) => setText(e.target.value)}/><br /><br />
                <Button type='submit' primary floated="right">Save</Button>
                <Button floated="right" onClick={() => setText(originalText)}>Cancel</Button>
            </Form>
        </Card.Content>
    </Card>;

}

export default SingleFieldCard;